import * as THREE from 'three';
import { XRButton } from 'three/addons/webxr/XRButton.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

export function setupVariables(sceneInstance) {
    sceneInstance.fileName = "";//The .glb file name extracted from the post command sent to pear-drop
    sceneInstance.copyright="";//the .glb copyright 
    sceneInstance.cityModel = null;//the generated .glb 
    sceneInstance.OriginalcityModel=null; //to save the original model
    sceneInstance.isOriginalModelDisplayed=true;//boolean to detect if the original .glb without airquality overlay is being rendered or not

    //HTMLMesh used to render the map viewer
    sceneInstance.htmlMesh = null;
    sceneInstance.map = null;

    //bool to keep track when when map or 3D model has been loaded
    sceneInstance.CurrentScene = 0;

    //UI
    sceneInstance.colors = {
        keyboardBack: 0x858585,
        panelBack: 0xFFFFFF,
        button: 0x363636,
        hovered: 0x1c1c1c,
        selected: 0x109c5d
    };
    //UI elements to test raycasting events
    sceneInstance.objsToTest = [];

    //main menu elements
    sceneInstance.MainMenu_InstructionIndex = 0;
    sceneInstance.MainMenu_TextInputFieldIndex = 0;
    sceneInstance.MainMenu_SavedDataTextIndex = 0;
    //textinput placeholder
    sceneInstance.placeholderText = 'Search a city (e.g. Paris)';

    //map elements
    sceneInstance.Map_BackButtonIndex = 0;
    sceneInstance.Map_SquareMarkerMeshIndex = 0;
    sceneInstance.Map_SquareMarkerBorderIndex = 0;
    sceneInstance.Map_SelectAreaIndex = 0;

    //Air quality UI elements
    sceneInstance.AirQuality_AreaNameIndex=0;
    sceneInstance.AirQuality_TitleIndex=0;
    sceneInstance.AirQuality_DescriptionTextIndex=0;
    sceneInstance.AirQuality_IndicatorIndex=0;
    sceneInstance.AirQuality_ToggleIndex=0;
    sceneInstance.AirQuality_CopyrightIndex=0;
    sceneInstance.AIrqualityIndicatorImage=null;
    
    //get map div
    sceneInstance.mapContainer = document.getElementById("map-container");

    sceneInstance.GoBackButton=null;
    sceneInstance.keyboard=null;
    sceneInstance.StartButton=null;
    sceneInstance.ToggleUIGroup=null;

    //data story buttons
    sceneInstance.Storybuttons=[];
    sceneInstance.StoryCoords= [
        [48.9063814971222, 2.365659519673659, "Paris"], // Paris Olympics 2024
        [139.6917, 35.6895, "London"], // London
    ];

    sceneInstance.MyLoadingBar = null;//variable for saving the loading bar
}

export function createScene(sceneInstance) {
    sceneInstance.scene = new THREE.Scene();
    sceneInstance.scene.background = new THREE.Color(0x808080);

    sceneInstance.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 10);
    sceneInstance.camera.position.set(0, 1.6, 3);

    const floorGeometry = new THREE.PlaneGeometry(6, 6);
    const floorMaterial = new THREE.ShadowMaterial({ opacity: 0.25, blending: THREE.CustomBlending, transparent: false });
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.rotation.x = - Math.PI / 2;
    floor.receiveShadow = true;
    sceneInstance.scene.add(floor);

    sceneInstance.scene.add(new THREE.HemisphereLight(0xbcbcbc, 0xa5a5a5, 3));

    const light = new THREE.DirectionalLight(0xffffff, 3);
    light.position.set(0, 6, 0);
    light.castShadow = true;
    light.shadow.camera.top = 3;
    light.shadow.camera.bottom = - 3;
    light.shadow.camera.right = 3;
    light.shadow.camera.left = - 3;
    light.shadow.mapSize.set(4096, 4096);
    sceneInstance.scene.add(light);

    // custom mesh
    sceneInstance.group = new THREE.Group();
    sceneInstance.scene.add(sceneInstance.group);

    //initiate textureloader
    sceneInstance.textureLoader = new THREE.TextureLoader();

    //raycaster
    sceneInstance.raycaster = new THREE.Raycaster();
}

export function setupData(sceneInstance) {
    //json texture canvas
    sceneInstance.ctx=null;//the canvas in which json air quality is being drawn on
    sceneInstance.jsonColors=[];//the colors in the json airquality(we save it here to refer to in other functions)
    sceneInstance.customTexture=null;//the texture generated from the json airquality

    //geoCorners(reference points to the corners of the map viewer)
    sceneInstance.geoCorners = {
        topRight: { lng: -0.04617, lat: 51.51754 },
        bottomLeft: { lng: -0.11983, lat: 51.49151 }
    };
        // Define the mapping of the corners in scene coordinates to geographical coordinates
    sceneInstance.sceneCorners = {
        topRight: { x: 0.6475648726049507, y: 1.8867202718262015 },
        bottomLeft: { x: -0.6492540657223348, y: 1.2782447929789522 }
    };

    //current map center
    sceneInstance.originalCenterLng = 0;
    sceneInstance.originalCenterLat = 0;
    
    //the coordinates the user has selected
    sceneInstance.SelectedLongtitude = 0;
    sceneInstance.SelectedLatitude = 0;

    //user's selected city
    sceneInstance.SelectedAddressName="";
}

export function createRenderer(sceneInstance) {
    sceneInstance.renderer = new THREE.WebGLRenderer({ antialias: true });
    sceneInstance.renderer.setPixelRatio(window.devicePixelRatio);
    sceneInstance.renderer.setSize(window.innerWidth, window.innerHeight);
    sceneInstance.renderer.shadowMap.enabled = true;
    sceneInstance.renderer.xr.enabled = true;

    document.body.appendChild(XRButton.createButton(sceneInstance.renderer));
    document.body.appendChild( sceneInstance.renderer.domElement );
}

export function setupLoaders(sceneInstance) {
    sceneInstance.dracoLoader = new DRACOLoader();
    sceneInstance.dracoLoader.setDecoderPath(`/assets/draco/`);

    sceneInstance.gltfLoader = new GLTFLoader();
    sceneInstance.gltfLoader.setDRACOLoader(sceneInstance.dracoLoader);
}