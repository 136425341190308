import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';

//assets imported
import FontJSON from './assets/Roboto-Regular.json';
import FontImage from './assets/Roboto-Regular.png';
import Backspace from './assets/backspace.png';
import Enter from './assets/enter.png';
import Shift from './assets/shift.png';

export function makeKeyboard(sceneInstance, language) {
    sceneInstance.keyboard = new ThreeMeshUI.Keyboard({
        language: language,
        fontFamily: FontJSON,
        fontTexture: FontImage,
        fontSize: 0.035, // fontSize will propagate to the keys blocks
        backgroundColor: new THREE.Color(sceneInstance.colors.keyboardBack),
        backgroundOpacity: 1,
        backspaceTexture: Backspace,
        shiftTexture: Shift,
        enterTexture: Enter
    });

    sceneInstance.keyboard.position.set(0, 1.05, -1);
    sceneInstance.keyboard.rotation.x = -0.55;
    sceneInstance.scene.add(sceneInstance.keyboard);

    sceneInstance.keyboard.keys.forEach((key) => {

        sceneInstance.objsToTest.push(key);

        key.setupState({
            state: 'idle',
            attributes: {
                offset: 0,
                backgroundColor: new THREE.Color(sceneInstance.colors.button),
                backgroundOpacity: 1
            }
        });

        key.setupState({
            state: 'hovered',
            attributes: {
                offset: 0,
                backgroundColor: new THREE.Color(0x999999),
                backgroundOpacity: 1
            }
        });

        key.setupState({
            state: 'selected',
            attributes: {
                offset: -0.009,
                backgroundColor: new THREE.Color(sceneInstance.colors.selected),
                backgroundOpacity: 1
            },
            // triggered when the user clicked on a keyboard's key
            onSet: () => {
                //delete placeholder if it exists
                if (sceneInstance.keyboardInputText.content == sceneInstance.placeholderText) {
                    sceneInstance.keyboardInputText.set({ content: '' });
                }

                // if the key have a command (eg: 'backspace', 'switch', 'enter'...)
                // special actions are taken
                if (key.info.command) {

                    switch (key.info.command) {

                        // switch between panels
                        case 'switch':
                            sceneInstance.keyboard.setNextPanel();
                            break;

                        // switch between panel charsets (eg: russian/english)
                        case 'switch-set':
                            sceneInstance.keyboard.setNextCharset();
                            break;

                        case 'enter':
                            console.log(sceneInstance.keyboardInputText.content);

                            if (sceneInstance.CurrentScene == 0) {
                                //reset mainmenu
                                sceneInstance.CurrentScene = 1;//1 is the map scene
                                sceneInstance.uiContainer.children[sceneInstance.MainMenu_InstructionIndex].visible = false; //hide main menu instruction
                                sceneInstance.keyboardInputText.visible = false;//hide input text
                                sceneInstance.uiContainer.children[sceneInstance.MainMenu_TextInputFieldIndex].visible = false;//hide input text field
                                sceneInstance.uiContainer.children[sceneInstance.MainMenu_SavedDataTextIndex].visible = false;//hide input text field
                                //todo disable saved data buttons
                                sceneInstance.Storybuttons.forEach((obj) => {
                                    obj.visible=false;                                                             
                                });

                                //Set map coordinates
                                //move map center to the new coordinates
                                sceneInstance.SelectedAddressName=sceneInstance.keyboardInputText.content;
                                sceneInstance.convertAddressToCoordinates(sceneInstance.keyboardInputText.content)
                                    .then(coordinates => {
                                        {
                                            sceneInstance.UpdateMapCenter(coordinates).then(() => {
                                            }).catch(error => {
                                                console.error(error);
                                            });
                                            //save the current center values
                                            sceneInstance.originalCenterLng = coordinates.longitude;
                                            sceneInstance.originalCenterLat = coordinates.latitude;
                                        }
                                    })
                                    .catch(error => console.error(error));
                            
                                //enable back button
                                sceneInstance.uiContainer.children[sceneInstance.Map_BackButtonIndex].visible = true;
                                //enable map
                                if (sceneInstance.htmlMesh != null) {
                                    sceneInstance.htmlMesh.visible = true;
                                }
                                //enable square marker
                                sceneInstance.uiContainer.children[sceneInstance.Map_SquareMarkerMeshIndex].visible = true;
                                sceneInstance.uiContainer.children[sceneInstance.Map_SquareMarkerBorderIndex].visible = true;
                                sceneInstance.uiContainer.children[sceneInstance.Map_SelectAreaIndex].visible = true;

                            } else if(sceneInstance.CurrentScene==1) {
                                sceneInstance.CurrentScene = 2;
                                sceneInstance.keyboard.visible = false;//hide keyboard

                                //Hide map
                                if (sceneInstance.htmlMesh != null) {
                                    sceneInstance.htmlMesh.visible = false;
                                }
                                //enable square marker
                                sceneInstance.uiContainer.children[sceneInstance.Map_SquareMarkerMeshIndex].visible = false;
                                sceneInstance.uiContainer.children[sceneInstance.Map_SquareMarkerBorderIndex].visible = false;
                                sceneInstance.uiContainer.children[sceneInstance.Map_SelectAreaIndex].visible = false;
                                
                                //fetch AirQuality Data
                                //Generate model
                                //Display UI
                                sceneInstance.SelectedLongtitude = sceneInstance.map.getCenter().x;
                                sceneInstance.SelectedLatitude = sceneInstance.map.getCenter().y;
                                                
                                sceneInstance.uiContainer.children[sceneInstance.Map_BackButtonIndex].visible = false;//disable back button
                                sceneInstance.MyLoadingBar.show();           // Make the loader visible
                                sceneInstance.FetchAirQualityData().then(()=>{
                                    setTimeout(() => {
                                        sceneInstance.GenerateCity();
                                    }, 1000); // 1000 milliseconds delay
                                });
                            }
                            break;

                        case 'space':
                            sceneInstance.keyboardInputText.set({ content: sceneInstance.keyboardInputText.content + ' ' });
                            break;

                        case 'backspace':
                            if (!sceneInstance.keyboardInputText.content.length) break;
                            sceneInstance.keyboardInputText.set({
                                content: sceneInstance.keyboardInputText.content.substring(0, sceneInstance.keyboardInputText.content.length - 1) || ''
                            });
                            break;

                        case 'shift':
                            sceneInstance.keyboard.toggleCase();
                            break;
                    }
                // print a glyph, if any
                } else if (key.info.input) {
                    sceneInstance.keyboardInputText.set({ content: sceneInstance.keyboardInputText.content + key.info.input });
                }
                //add placeholder if no text exists
                if (sceneInstance.keyboardInputText.content == '' && !sceneInstance.keyboardInputText.content.length && sceneInstance.CurrentScene == 0) {
                    sceneInstance.keyboardInputText.set({ content: sceneInstance.placeholderText });
                }
            }
        });
    });
}