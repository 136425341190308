import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import LoadingBar from './LoadingBar.js';

import { makeKeyboard } from './keyboard.js';

//assets imported
import FontJSON from './assets/Roboto-Regular.json';
import FontImage from './assets/Roboto-Regular.png';
import Logo from './assets/Logo.jpg';
import backArrow from './assets/back-arrow.png';

export function DrawMainMenu(sceneInstance){
    //loading bar initialization
    sceneInstance.MyLoadingBar = new LoadingBar(sceneInstance.scene);
    sceneInstance.MyLoadingBar.hide();  
    
    var _text = '';
    var _textWidth = 0.5;
    var _textHeight = 0.1;
    var _fontSize = 0.055;
    var _textfontColor = new THREE.Color(0x262626);
    var _textbgColor = new THREE.Color(0xFFFFFF);
    var _textPos = new THREE.Vector3(-.37, .31, .001);
    var _borderradius=0;
    //draw logo
    var _imagePos = new THREE.Vector3(-0.37, 0.31, .01);                                       
    sceneInstance.DrawTexture(Logo,0.5,.058,_imagePos,sceneInstance.uiContainer);
                                        
    //instruction Text
    _text = 'What city would you like to explore?';
    _textWidth = 1;
    _textHeight = 0.01;
    _fontSize = 0.045;
    _textfontColor = new THREE.Color(0x262626);
    _textbgColor = new THREE.Color(0xFFFFFF);
    _textPos = new THREE.Vector3(0, .18, 0.001);

    sceneInstance.MainMenu_InstructionIndex = sceneInstance.DrawText(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, 1, _textPos, sceneInstance.uiContainer);

    //Input field     
    sceneInstance.keyboardInputText = new ThreeMeshUI.Text({ content: sceneInstance.placeholderText });

    const textField = new ThreeMeshUI.Block({
        fontFamily: FontJSON,
        fontTexture: FontImage,
        width: 1,
        height: 0.1,
        fontSize: 0.033,
        padding: 0.03,
        backgroundOpacity: 1,
        borderRadius: 0.03,
        fontColor: new THREE.Color(0xFFFFFF),
        backgroundColor: new THREE.Color(0x262626)
    }).add(sceneInstance.keyboardInputText);
    
    textField.setupState( {
        state: 'idle',
        attributes: {
            offset: 0.02,
            backgroundColor: new THREE.Color(0x262626),
            backgroundOpacity: 1
        }
    } );

    textField.setupState( {
        state: 'hovered',
        attributes: {
            offset: 0.02,
            backgroundColor: new THREE.Color(0x222024),
            backgroundOpacity: 1
        }
    });

    textField.setupState( {
        state: 'selected',
        attributes: {
            offset: 0.01,
            backgroundColor: new THREE.Color(0x222024),
            backgroundOpacity: 1
        },
        onSet: () => {
            if(!sceneInstance.keyboard){
                makeKeyboard(sceneInstance); //open keyboard
            }
            sceneInstance.keyboard.visible=true;
        }} 
    );

    sceneInstance.objsToTest.push(textField);
    textField.position.set(0, .07, .001);
    sceneInstance.uiContainer.add(textField);
    sceneInstance.MainMenu_TextInputFieldIndex = sceneInstance.uiContainer.children.length - 1;

    //Saved Data stories text
    _text = 'Saved Data stories';
    _textWidth = 1;
    _textHeight = 0.03;
    _fontSize = 0.045;
    _textfontColor = new THREE.Color(0x262626);
    _textbgColor = new THREE.Color(0xFFFFFF);
    _textPos = new THREE.Vector3(0, -0.07, 0.001);
    
    sceneInstance.MainMenu_SavedDataTextIndex = sceneInstance.DrawText(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, 1, _textPos, sceneInstance.uiContainer);
    //saved data stories grid
    for(let i=0;i<4;i++){
        if(!sceneInstance.StoryCoords[i]){
            //draw button 
            _text="";
            _textWidth = 0.23;
            _textHeight = 0.23;
            _textfontColor = new THREE.Color(0xFFFFFF);
            _textbgColor = new THREE.Color(0xd3d3d3);
            _textPos = new THREE.Vector3(-0.41+((i-1)*0.27)+0.27, -0.23, .02);
            _borderradius=0;
            sceneInstance.Storybuttons.push(sceneInstance.DrawButton(_text,_textWidth,_textHeight,0.045,_textPos,_textfontColor,_textbgColor,_borderradius,"",null,sceneInstance.uiContainer));
        } else{
                //draw button 
            _text=sceneInstance.StoryCoords[i][2];
            _textWidth = 0.23;
            _textHeight = 0.23;
            _textfontColor = new THREE.Color(0xFFFFFF);
            _textbgColor = new THREE.Color(0x000000);
            _textPos = new THREE.Vector3((-0.41+((i-1)*0.27)+0.27), -0.23, .02);
            _borderradius=0;
            var args=i;
            sceneInstance.Storybuttons.push(sceneInstance.DrawButton(_text,_textWidth,_textHeight,0.045,_textPos,_textfontColor,_textbgColor,_borderradius,"SavedDataStories",args,sceneInstance.uiContainer));
        }
    }

    //draw go-back button 
    _text="Start";
    _textWidth = 0.15;
    _textHeight = 0.15;
    _textfontColor = new THREE.Color(0x262626);
    _textbgColor = new THREE.Color(0xFFFFFF);
    _textPos = new THREE.Vector3(-0.75, 0, .001);
    _borderradius=0.075;
    sceneInstance.GoBackButton=sceneInstance.DrawButton("",_textWidth,_textHeight,null,_textPos,_textfontColor,_textbgColor,_borderradius,"GoBackToMenu",null,sceneInstance.uiContainer);
    
    //create button texture
    const arrowTexture = sceneInstance.textureLoader.load(backArrow);
    const arrowMaterial = new THREE.MeshBasicMaterial({
        map: arrowTexture,
        transparent: true, // Set transparency true if your image has transparent areas
        color: 0xffffff // Optional: set color to white to keep the original image color
    });
    const arrowMesh = new THREE.Mesh(new THREE.PlaneGeometry(0.1, 0.1), arrowMaterial); // Adjust the size as needed
    // Add the arrow mesh to the button
    sceneInstance.GoBackButton.add(arrowMesh);

    // Position the arrow mesh within the button if needed
    arrowMesh.position.set(0, 0, 0.01); // Adjust this to ensure the mesh is visible and properly positioned
            
    sceneInstance.Map_BackButtonIndex = sceneInstance.uiContainer.children.length - 1;//save the index of the back button 
    sceneInstance.uiContainer.children[sceneInstance.Map_BackButtonIndex].visible = false;//disable the button to only enable when needed
    //initialize map
    var coordinates = { longitude: -0.0830633, latitude: 51.5044756 };
    sceneInstance.LoadMap(coordinates);
    //initialize map's square marker
    sceneInstance.DrawSquareMarker();
}