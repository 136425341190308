import firebaseInstance from './firebaseInit';
import FirebaseAuth from './firebaseAuth';
import WebXRScene from './webXRScene';
import PinInput from './components/PinInput';
import './styles/main.css';

const firebaseAuth = new FirebaseAuth(firebaseInstance.app);
const errorMsgContents = "Invalid PIN entered";
const webxrContainer = 'web-xr-content';
const loginContainer = 'pin-input-container';
var sceneInstance = null;

const onPinInputComplete = (pinValue) => {
    firebaseAuth.signIn('user@test.com', pinValue)
        .then((userCredential) => {
            // Hide login and show WebXR content
            document.getElementById(loginContainer).style.display = 'none';
            document.getElementById(webxrContainer).style.display = 'block';
            initializeWebXR(); // Function to initialize WebXR content
        })
        .catch((error) => {
            document.getElementById('error-msg').textContent = errorMsgContents;
            setTimeout(() => {
                pinInput.unfreezeInputs();
              }, 2000);
        });
  };

const pinInput = new PinInput(onPinInputComplete);
document.body.appendChild(pinInput.render());

// Check auth state on page load
firebaseAuth.onAuthStateChanged(user => {
    if (user) {
        console.log('User is signed in:', user);
        document.getElementById(loginContainer).style.display = 'none';
        document.getElementById(webxrContainer).style.display = 'block';
        initializeWebXR(); // Function to initialize WebXR content
    } else {
        console.log('No user is signed in.');
        document.getElementById(loginContainer).style.display = 'block';
        document.getElementById(webxrContainer).style.display = 'none';
    }
});

function initializeWebXR() {
    if (sceneInstance === null)
    {
        sceneInstance = new WebXRScene(webxrContainer);
    }
}