import * as THREE from 'three';

export function loadglb(sceneInstance, scene, url, startPos = new THREE.Vector3(0, 0.4, 0)) {
    sceneInstance.gltfLoader.load(
        url,
        (object) => {
            sceneInstance.cityModel = object;

            scene.add(sceneInstance.cityModel.scene);
            sceneInstance.cityModel.scene.position.add(startPos);
            sceneInstance.cityModel.scene.scale.set(0.0005, 0.0005, 0.0005);
            sceneInstance.OriginalcityModel=sceneInstance.cityModel.scene.clone();
            scene.add(sceneInstance.OriginalcityModel);
            
            //blend airquality texture with each tile 
            const MysquareSize = 45; // This should match the square size used in texture creation
            const maxX = 256 - 1;
            const maxY = 256 - 1;
            let meshNum = 0;
            let prevBlendColor = null;

            if(sceneInstance.jsonColors[0]){
                sceneInstance.cityModel.scene.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        // Calculate the center pixel coordinates of the corresponding square
                        const col = meshNum % Math.floor(maxX / MysquareSize);
                        const row = Math.floor(meshNum / Math.floor(maxX / MysquareSize));
                        const centerX = Math.min((col * MysquareSize) + Math.floor(MysquareSize / 2), maxX);
                        const centerY = Math.min((row * MysquareSize) + Math.floor(MysquareSize / 2), maxY);

                    // Check if the centerX and centerY are within bounds
                    if (centerX < 0 || centerX > maxX || centerY < 0 || centerY > maxY) {
                        console.warn(`Coordinates out of bounds: centerX=${centerX}, centerY=${centerY}`);
                        return;
                    }

                    // Get the color from the center pixel
                    const pixelData = sceneInstance.ctx.getImageData(centerX, centerY, 1, 1).data;
                    let blendColor = null;
                    if (pixelData[0] === 0 && pixelData[1] === 0 && pixelData[2] === 0){
                        blendColor = prevBlendColor;
                    } else {
                        blendColor = new THREE.Vector3(pixelData[0] / 255, pixelData[1] / 255, pixelData[2] / 255);
                    }

                    const originalTexture = child.material.map;

                    // Create a new shader material using the color from the center pixel
                    child.material = new THREE.ShaderMaterial({
                        uniforms: {
                            originalTexture: { value: originalTexture },
                            blendColor: { value: blendColor },
                            blendFactor: { value: 0.3 } // Adjust blend factor to taste
                        },
                        vertexShader: `
                            varying vec2 vUv;

                            void main() {
                                vUv = uv;
                                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                            }
                        `,
                        fragmentShader: `
                            uniform sampler2D originalTexture;
                            uniform vec3 blendColor;
                            uniform float blendFactor;
                            varying vec2 vUv;

                            void main() {
                                vec4 originalColor = texture2D(originalTexture, vUv);
                                vec4 colorFromData = vec4(blendColor, 1.0); // Assume alpha is always 1
                                // Blend the original texture color with the RGB color
                                gl_FragColor = mix(originalColor, colorFromData, blendFactor);
                            }
                        `,
                        transparent: true
                    });
                            child.material.needsUpdate = true;
                            meshNum++;
                            prevBlendColor=blendColor;                                
                        }
                    });
                }
            console.log("Total number of meshes: ",meshNum );

            sceneInstance.MyLoadingBar.hide();// Make the loader visible
            sceneInstance.DisplayCityUI();// Display the UI info about the city

            // Enable back button
            sceneInstance.uiContainer.children[sceneInstance.Map_BackButtonIndex].visible = true;
            sceneInstance.cityModel.scene.visible=false;
        },
        (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
            console.log('Error loading model: ' + error);
        }
    );
}