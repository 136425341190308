//scripts and libraries imported
import * as THREE from 'three';
import * as maptalks from 'maptalks';
import { HTMLMesh } from 'three/examples/jsm/interactive/HTMLMesh.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import ThreeMeshUI from 'three-mesh-ui';

import { createControls } from './controls.js';
import { loadglb } from './loadModel.js';
import { makeKeyboard } from './keyboard.js';
import { createScene, setupData, createRenderer, setupVariables, setupLoaders } from './sceneInitialization.js';
import { DrawMainMenu } from './mainMenu.js';

//assets imported
import FontJSON from './assets/Roboto-Regular.json';
import FontImage from './assets/Roboto-Regular.png';
import Airquality_Data_Indicator_Image from './assets/Airquality_Data_Indicator.png';
import Airquality_AttributionLogo from './assets/GoogleLogo.png';
import ColoredLogo from './assets/LogoColored.jpg';

class WebXRScene {

    constructor(container) {

        this.initialize()
            .then(() => {
                // Call the scene renderer only after initialization is complete
                this.setContainer(container);
                this.animate();
            })
            .catch(error => {
                console.error('Initialization failed:', error);
            });
    }

    async initialize() {
        this.google_api_key = 'AIzaSyDW6h1TqWopgNMJFvHegxPHHH3-48YPSyU';//google api key to convert address to coordinates and vise versa

        //functions all imported from sceneInitialization.js
        setupVariables(this); //Configures initial variables

        createScene(this);

        setupData(this); //Sets up JSON and Geographic data

        createRenderer(this);

        this.makeUI(); //initialize the UI 

        createControls(this); //Sets up hand and VR controls

        setupLoaders(this); //Sets up GLTF and Draco loaders

        // Events
        window.addEventListener('resize', this.onWindowResize);
        console.log("Web XR Scene created: ", this);
    }
 
    setContainer = (containerID) => {
        this.container = document.getElementById(containerID);
        this.container.appendChild(this.renderer.domElement);

        this.controls = new OrbitControls(this.camera, this.container);
        this.controls.target.set(0, 1, -1);
        this.controls.update();
    }

    loadCity = () => {
        const meshStartPosition = new THREE.Vector3(0, 0.8, -0.3)
        if (this.cityModel != null) {
            this.clearScene(this.cityModel.scene);
        }
        const cityURI = "https://peardrop-image-xdjp3vv33q-nw.a.run.app/file/" + this.fileName;//URI to download the .glb of the selected city

        loadglb(this, this.group, cityURI, meshStartPosition);
    }

    onWindowResize = () => {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    // Function to swap models(Airquality overlay and normal .glb)
    swapModels() {
        if (this.isOriginalModelDisplayed) {
            this.OriginalcityModel.visible = false;
            this.cityModel.scene.visible = true;
        } else {
            this.OriginalcityModel.visible = true;
            this.cityModel.scene.visible = false;
        }
        this.isOriginalModelDisplayed = !this.isOriginalModelDisplayed;
    }

    //function to get .glb city from server
    async GenerateCity() {
        // JSON data to send
        const postData = {
            "region": {
                "latitude": this.SelectedLatitude,
                "longitude": this.SelectedLongtitude,
                "distance": "1000",
                "resolution": "400"
            },
            "modelInfo": {
                "LOD": "1",
                "shouldJoin": "true",
                "fileType": "glb"
            }
        };
        // URL to send the POST request to
        const ModelURL = "https://peardrop-image-xdjp3vv33q-nw.a.run.app/get-tiles";

        // Send the POST request with the JSON data
        fetch(ModelURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json(); // or response.text() if the response is text-based
            })
            .then(data => {
                if (data.mapInfo) {
                    if (data.mapInfo.filename && data.mapInfo.filename.length > 0) {
                        this.fileName = data.mapInfo.filename[0];
                    } else {
                        throw new Error("Filename not found in the response");
                    }
        
                    if (data.mapInfo.copyright) {
                        this.copyright = data.mapInfo.copyright;
                    } else {
                        throw new Error("Copyright not found in the response");
                    }
        
                    this.loadCity();
                } else {
                    throw new Error("mapInfo not found in the response");
                }
            })
            .catch(error => {
                console.error("There was a problem with the fetch operation:", error);
            });

    }

    //function to download airquality data from server
    async FetchAirQualityData() {
        // JSON data to send
        const postData = {
            "region": {
                "latitude": this.SelectedLatitude,
                "longitude": this.SelectedLongtitude,
                "distance": "1000",
                "resolution": "400"
            }
        };
        const AirQualityDataURL = "https://peardrop-image-xdjp3vv33q-nw.a.run.app/sample-air-quality";
        // Send the POST request with the JSON data
        fetch(AirQualityDataURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json(); // or response.text() if the response is text-based
        })
        .then(data => {
            console.log("Air quality raw data is: ",data);
            if(data){
                this.createTextureFromJson(data);
            }
        })
        .catch(error => {
            console.error("There was a problem with the fetch operation:", error);
        });
    }

    clearScene(scene) {
        // Remove all objects from the scene
        scene.children.forEach(child => {
            scene.remove(child);
        });

        // Dispose of any resources associated with the removed objects
        scene.traverse(obj => {
            if (obj instanceof THREE.Mesh) {
                if (obj.geometry) {
                    obj.geometry.dispose();
                }
                if (obj.material) {
                    if (Array.isArray(obj.material)) {
                        obj.material.forEach(material => {
                            material.dispose();
                        });
                    } else {
                        obj.material.dispose();
                    }
                }
            }
        });

        // Set the scene to null (optional)
        scene = null;
    }

    animate() {
        console.log("Animating: ", this);
        this.renderer.setAnimationLoop(this.render);
    }

    //--------------------------Maptalks---------------------------
    InitializeGeoCorners(newCenterLng, newCenterLat) {

        // Calculate the shift needed
        const lngShift = newCenterLng - this.originalCenterLng;
        const latShift = newCenterLat - this.originalCenterLat;

        // Calculate new geoCorners based on the center
        this.geoCorners = {
            topRight: {
                lng: this.geoCorners.topRight.lng + lngShift,
                lat: this.geoCorners.topRight.lat + latShift
            },
            bottomLeft: {
                lng: this.geoCorners.bottomLeft.lng + lngShift,
                lat: this.geoCorners.bottomLeft.lat + latShift
            }
        };
    }

    sceneToGeo(x, y) {
        // Calculate the percentage of x and y within the scene rectangle
        const xPercent = (x - this.sceneCorners.bottomLeft.x) / (this.sceneCorners.topRight.x - this.sceneCorners.bottomLeft.x);
        const yPercent = (y - this.sceneCorners.bottomLeft.y) / (this.sceneCorners.topRight.y - this.sceneCorners.bottomLeft.y);

        // Interpolate geographical coordinates based on percentage
        const lng = this.geoCorners.bottomLeft.lng + xPercent * (this.geoCorners.topRight.lng - this.geoCorners.bottomLeft.lng);
        const lat = this.geoCorners.bottomLeft.lat + yPercent * (this.geoCorners.topRight.lat - this.geoCorners.bottomLeft.lat);

        return { lng, lat };
    }

    LoadMap(coordinates) {
        //get map div
        this.mapContainer = document.getElementById("map-container");
        if (!this.mapContainer) {
            console.error("Map container not found");
            return;
        }

        //initlaize map
        this.map = new maptalks.Map(this.mapContainer, {
            center: [coordinates.longitude, coordinates.latitude],
            zoom: 15,
            pitch: 0,
            bearing: 0,
            centerCross: false,
            doubleClickZoom: false,
            baseLayer: new maptalks.TileLayer('base', {
                urlTemplate: 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
                subdomains: ['a', 'b', 'c', 'd', 'e']
            }),
            layers: [
                new maptalks.VectorLayer('v')
            ]
        });

        //save the current center values
        this.originalCenterLng = coordinates.longitude;
        this.originalCenterLat = coordinates.latitude;
        // HTMLMesh
        this.htmlMesh = new HTMLMesh(this.mapContainer);
        this.htmlMesh.position.set(0, -0.07, .005);
        this.htmlMesh.scale.set(.755, 0.62, 0.1);
        this.uiContainer.add(this.htmlMesh);

        //initialize corners
        this.InitializeGeoCorners(coordinates.longitude, coordinates.latitude);

        this.htmlMesh.visible = false;
    }

    //we convert the city name the user searched for to coordinates 
    async convertAddressToCoordinates(address) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${this.google_api_key}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.status === 'OK') {
                const latitude = data.results[0].geometry.location.lat;
                const longitude = data.results[0].geometry.location.lng;

                return { longitude, latitude };
            } else {
                throw new Error('Geocode was not successful for the following reason: ' + data.status);
            }
        } catch (error) {
            console.error('Failed to fetch coordinates:', error, ' going to london...');
            const latitude = 51.5044756;
            const longitude = -0.0830633;
            return { longitude, latitude };
        }
    }

    //used to detect if city names that are fetched from google's api have special characters that arent supported by the font
    containsSpecialCharacters(str) {
        // This regex matches any character that is not a basic ASCII character, ASCII character codes range from 32 (space) to 126 (~)
        const regex = /[^ -~]/;

        return regex.test(str);
    }

    //we convert the specific coordinates the user selected to an address to display on the top right 
    async convertCoordinatesToAddress() {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.SelectedLatitude},${this.SelectedLongtitude}&key=${this.google_api_key}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.status === 'OK') {
                const fullAddress = data.results[0].formatted_address;
                // Split the address by commas and strip leading/trailing whitespace
                const parts = fullAddress.split(',').map(part => part.trim());
                // Assuming the area is the second element in the address
                const area = parts.length > 1 ? parts[1] : 'Area not found';
                console.log("Selected area is: ",area);
                if(!this.containsSpecialCharacters(area)){
                    return area;
                } else{
                    console.log("Address has special characters, using user's input: ",this.SelectedAddressName );
                    return this.SelectedAddressName;
                }
            } else {
                throw new Error('Reverse geocode was not successful for the following reason: ' + data.status);
            }
        } catch (error) {
            console.error('Failed to fetch address:', error, ' defaulting to a known location...');
            return '1600 Amphitheatre Parkway, Mountain View, CA 94043, USA'; // Default location
        }
    }

    //--------------------------Maptalks---------------------------
    DrawButton(text, width, height,fontSize=0.4, pos,fontColor,backgroundColor,borderRadius,FunctionToInvoke, args ,targetContainer) {
        var Button = new ThreeMeshUI.Block({
            fontFamily: FontJSON,
            fontTexture: FontImage,
            width: width,
            height: height,
            justifyContent: 'center',
            offset: 0.00, // Adjust the offset to ensure proper alignment
            margin: 0.1,
            borderRadius: borderRadius,
            fontColor: fontColor,
            backgroundColor: backgroundColor,
            backgroundOpacity: 1
        }).add(
            new ThreeMeshUI.Text({ 
                offset:0,
                fontSize: fontSize,
                content: text 
            })
        );

        // Button attributes
        const selectedAttributes = {
            offset: 0.0,
            backgroundColor: new THREE.Color(0x777777),
            fontColor: new THREE.Color(0x222222)
        };

        const hoveredStateAttributes = {
            state: 'hovered',
            attributes: {
                offset: 0.0,
                backgroundColor: new THREE.Color(0xD3D3D3),
                backgroundOpacity: 1,
                fontColor: new THREE.Color(0x000000)
            },
        };

        const idleStateAttributes = {
            state: 'idle',
            attributes: {
                offset: 0.0,
                backgroundColor: backgroundColor,
                backgroundOpacity: 1,
                fontColor: fontColor
            },
        };

        // Button setup
        Button.position.set(pos.x,pos.y,pos.z);
        Button.setupState({
            state: 'selected',
            attributes: selectedAttributes,
            onSet: () => {
                console.log("Button pressed");
                if (typeof this[FunctionToInvoke] === 'function') {
                    if(args==null){
                       
                        this[FunctionToInvoke]();
                    }else{
                        this[FunctionToInvoke](args);
                    }
                } else {
                    console.error(`Function ${FunctionToInvoke} does not exist in WebXRScene.`);
                }
            }
        });
        
        Button.setupState(hoveredStateAttributes);
        Button.setupState(idleStateAttributes);
        
        targetContainer.add(Button);
        this.objsToTest.push(Button);

        return Button;
    }

    DrawTexture(texture, width, height, pos, targetContainer) {
        return new Promise((resolve, reject) => {
            this.textureLoader.load(texture, (texture) => {
                // Create a plane geometry
                const geometry = new THREE.PlaneGeometry(width, height);
                
                // Create a material with the texture and blend it with a white background
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                    color: 0xffffff, // White color
                    transparent: true,
                    opacity: 1.0,
                    combine: THREE.MixOperation, // Mix the color with the texture
                    reflectivity: 0.5 // Adjust this value to control the mix; 0.5 gives equal weight to both
                });
                material.premultipliedAlpha = true;
                
                // Create a mesh with the geometry and material
                const textureMesh = new THREE.Mesh(geometry, material);                 
                // Add the mesh to the target container
                targetContainer.add(textureMesh);

                // Set the position of the mesh
                textureMesh.position.set(pos.x, pos.y, pos.z);
    
                // Resolve the promise with the mesh
                resolve(textureMesh);
            });
        });
    }

    DrawText(text, width, height, fontSize, fontColor, bgColor, bgOpacity, pos, targetContainer,alignment = 'center') {
        const Text = new ThreeMeshUI.Text({ content: text });

        const TextBlock = new ThreeMeshUI.Block({
            fontFamily: FontJSON,
            fontTexture: FontImage,
            width: width,
            height: height,
            fontSize: fontSize,
            backgroundOpacity: bgOpacity,
            fontColor: fontColor,
            backgroundColor: bgColor,
            borderColor:bgColor,
            borderRadius: 0,
            justifyContent: 'center', // This controls horizontal alignment
            textAlign: alignment // This controls vertical alignment, fixed to center here

        }).add(Text);
        targetContainer.add(TextBlock);
        TextBlock.position.set(pos.x, pos.y, pos.z);
        
        const index = targetContainer.children.length - 1;
        return index;
    }

    DrawTextBox(text, width, height, fontSize, fontColor, bgColor, bgOpacity, pos, targetContainer, textpadding, textBoxRadius) {
        const Text = new ThreeMeshUI.Text({ content: text });

        const TextBlock = new ThreeMeshUI.Block({
            fontFamily: FontJSON,
            fontTexture: FontImage,
            width: width,
            height: height,
            justifyContent: 'center',
            fontSize: fontSize,
            padding: textpadding,
            borderRadius: textBoxRadius,
            backgroundOpacity: bgOpacity,
            fontColor: fontColor,
            backgroundColor: bgColor
        }).add(Text);
        targetContainer.add(TextBlock);
        TextBlock.position.set(pos.x, pos.y, pos.z);
        
        const index = targetContainer.children.length - 1;
        return index;
    }

    // function to dynamically create texture from JSON data
    async createTextureFromJson(jsonPath) {
        try {
            const jsonData = jsonPath;
            console.log("response data is: ", jsonData);
    
            const textureWidth = 256; // Adjust as needed
            const textureHeight = 256;
            var squareSize = 45; // Define the size of the squares
    
            // Create a canvas for drawing the texture
            const canvas = document.createElement('canvas');
            canvas.width = textureWidth;
            canvas.height = textureHeight;
            this.ctx=canvas.getContext('2d', { willReadFrequently: true });

            // Collect the points
            const points = [];
    
            jsonData.data.forEach(item => {
                if (item.normalisedCoordinates && item.color) {
                    const { normalisedCoordinates, color } = item;
                    if(!color.blue){
                        color.blue=0;
                    }
                    const x = Math.round(normalisedCoordinates.longitude * (textureWidth - squareSize));
                    const y = Math.round((1 - normalisedCoordinates.latitude) * (textureHeight - squareSize));
    
                    points.push([x, y]);
    
                    const rgbColor = [
                        Math.round(color.red * 255),
                        Math.round(color.green * 255),
                        Math.round(color.blue * 255),
                    ];
                    this.jsonColors.push(rgbColor);
    
                    // Draw the point as a larger square
                    const colorRGB = `rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`;
                    this.ctx.fillStyle = colorRGB;
                    this.ctx.fillRect(x, y, squareSize, squareSize);
                }
            });
    
            if (points.length < 4) {
                throw new Error('Not enough valid points to draw squares');
            }
    
            // Function to calculate the distance between two points
            const distance = (point1, point2) => {
                const dx = point1[0] - point2[0];
                const dy = point1[1] - point2[1];
                return Math.sqrt(dx * dx + dy * dy);
            };
    
            // Draw larger squares based on proximity
            for (let i = 0; i < points.length; i++) {
                for (let j = i + 1; j < points.length; j++) {
                    for (let k = j + 1; k < points.length; k++) {
                        for (let l = k + 1; l < points.length; l++) {
                            const squarePoints = [points[i], points[j], points[k], points[l]];
    
                            // Check if points form a square based on proximity
                            const distances = [
                                distance(squarePoints[0], squarePoints[1]),
                                distance(squarePoints[1], squarePoints[2]),
                                distance(squarePoints[2], squarePoints[3]),
                                distance(squarePoints[3], squarePoints[0]),
                                distance(squarePoints[0], squarePoints[2]),
                                distance(squarePoints[1], squarePoints[3])
                            ];
    
                            // Approximate check for a square (all sides and diagonals should be roughly equal)
                            const approxEqual = (a, b) => Math.abs(a - b) < 5; // Adjust threshold as needed
                            if (
                                approxEqual(distances[0], distances[1]) &&
                                approxEqual(distances[1], distances[2]) &&
                                approxEqual(distances[2], distances[3]) &&
                                approxEqual(distances[0], distances[2]) &&
                                approxEqual(distances[1], distances[3])
                            ) {
                                // Calculate the color for the square
                                const colorValues = [this.jsonColors[i], this.jsonColors[j], this.jsonColors[k], this.jsonColors[l]];
                                const avgColor = [
                                    Math.round(colorValues.reduce((sum, c) => sum + c[0], 0) / 4),
                                    Math.round(colorValues.reduce((sum, c) => sum + c[1], 0) / 4),
                                    Math.round(colorValues.reduce((sum, c) => sum + c[2], 0) / 4),
                                ];

                                // Set the same stroke and fill style
                                const colorRGB = `rgb(${avgColor[0]}, ${avgColor[1]}, ${avgColor[2]})`;
                                this.ctx.strokeStyle = colorRGB; // Set the stroke color
                                this.ctx.fillStyle = colorRGB;   // Set the fill color
                                
                                // Draw the larger square
                                this.ctx.beginPath();
                                this.ctx.moveTo(squarePoints[0][0], squarePoints[0][1]);
                                this.ctx.lineTo(squarePoints[1][0], squarePoints[1][1]);
                                this.ctx.lineTo(squarePoints[2][0], squarePoints[2][1]);
                                this.ctx.lineTo(squarePoints[3][0], squarePoints[3][1]);
                                this.ctx.closePath();
    
                                this.ctx.stroke(); // Draw the stroke (outline)
                                this.ctx.fill();   // Fill the square
                            }
                        }
                    }
                }
            }
            
            // Create a Three.js texture from the canvas
            const texture = new THREE.CanvasTexture(canvas);
    
            // Apply the texture to a ShaderMaterial or other material
            const shaderMaterial = new THREE.ShaderMaterial({
                uniforms: {
                    customTexture: { value: texture }, // Use the created texture
                    // Add other uniforms as needed
                },
                vertexShader: `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }
                `,
                fragmentShader: `
                    varying vec2 vUv;
                    uniform sampler2D customTexture;
    
                    void main() {
                        gl_FragColor = texture2D(customTexture, vUv);
                    }
                `,
            });
            this.customTexture=texture;//save texture to use it later to blend with the .glb

            console.log('Custom texture created and applied to the scene');
        } catch (error) {
            console.error('Error loading or parsing JSON data:', error);
        }
    }

    makeUI() {
        this.uiContainer = new THREE.Group();
        this.uiContainer.position.set(0, 1.65, -1.16);
        this.scene.add(this.uiContainer);
        //draw text panel 
        const textPanel = new ThreeMeshUI.Block({
            fontFamily: FontJSON,
            fontTexture: FontImage,
            width: 1.3,
            height: 0.75,
            backgroundColor: new THREE.Color(this.colors.panelBack),
            backgroundOpacity: 1,
            fontColor: new THREE.Color(0x262626)
        });
        textPanel.position.set(0, 0, 0);
        this.uiContainer.add(textPanel);
        this.DisplayDisclaimerUI();
    }

    async UpdateMapCenter(coordinates) {
        var coord = new maptalks.Coordinate([coordinates.longitude + 0.00001, coordinates.latitude + 0.00001]);
        this.map.panTo(coord);
        //give time for the map to generate 
        //update geo-scene reference 
        this.InitializeGeoCorners(coord.x, coord.y);
        await new Promise(resolve => setTimeout(resolve, 1000));
        coord = new maptalks.Coordinate([coordinates.longitude - 0.00001, coordinates.latitude - 0.00001]);
        this.map.panTo(coord);
    }

    //function that closes the airquality show/hide button
    closeToggle(){
        this.ToggleUIGroup.visible = false;
    }

    //function that displays the ui shown when the .glb model is loaded
    DisplayCityUI(){
        var cityName='test';
        var _text=null;
        var _textWidth = 0.45;
        var _textHeight = 0.07;
        var _fontSize = 0.035;
        var _textfontColor = new THREE.Color(0xFFFFFF);
        var _textbgColor = new THREE.Color(0x000000);
        var _textbgOpacity =1;
        var _textPos = new THREE.Vector3(.4, .32, .01);
        var _textPadding = 0;
        var _textBoxRadius = 0;
        var borderRadius=0;
        
        //convert the user's coordinates to an address to display on the top right of the panel. Once that's been done we continue to draw the rest of the UI elements
        this.convertCoordinatesToAddress().then(userData => {           
            cityName=userData;
             _text=userData;
             _textWidth = 0.45;
             _textHeight = 0.07;
             _fontSize = 0.035;
             _textfontColor = new THREE.Color(0xFFFFFF);
             _textbgColor = new THREE.Color(0x000000);
             _textbgOpacity =1;
             _textPos = new THREE.Vector3(.4, .32, .01);
             _textPadding = 0;
             _textBoxRadius = 0;

            this.AirQuality_AreaNameIndex = this.DrawTextBox(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, _textbgOpacity, _textPos, this.uiContainer, _textPadding, _textBoxRadius);

            //draw title
             _text='CO2 emissions & particles';
             _textWidth = 0.5;
             _textHeight = 0.07;
             _fontSize = 0.035;
             _textfontColor = new THREE.Color(0xFFFFFF);
             _textbgColor = new THREE.Color(0x000000);
             _textbgOpacity =1;
             _textPos = new THREE.Vector3(-.3, .18, .01);
             _textPadding = 0;
             _textBoxRadius = 0;

            this.AirQuality_TitleIndex = this.DrawTextBox(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, _textbgOpacity, _textPos, this.uiContainer, _textPadding, _textBoxRadius);

            //draw description                                            
            _text = 'The below is a visualization related to CO2 emissions and particles in '+ cityName+'. \nThe visualization includes a horizontal gradient bar that represents air quality levels.';
            _textWidth = 1.1;
            _textHeight = 0.03;
            _fontSize = 0.03;
            _textfontColor = new THREE.Color(0x262626);
            _textbgColor = new THREE.Color(0xFFFFFF);
            _textPos = new THREE.Vector3(-.003, 0.03, 0.01);

            this.AirQuality_DescriptionTextIndex = this.DrawText(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, 1, _textPos, this.uiContainer,'left');
            
            if(this.AirQuality_ToggleIndex==0){
                //draw toggle pannel
                this.ToggleUIGroup = new THREE.Group();
                this.uiContainer.add( this.ToggleUIGroup);
                this.ToggleUIGroup.position.set(-0.75, -0.45, 0.5);
                this.AirQuality_ToggleIndex=this.uiContainer.children.length-1;

                var textPanel = new ThreeMeshUI.Block({
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    width: 0.4,
                    height: 0.45,
                    backgroundColor: new THREE.Color(this.colors.panelBack),
                    backgroundOpacity: 1,
                    fontColor: new THREE.Color(0x262626)
        
                });
                this.ToggleUIGroup.add(textPanel);
                textPanel.position.set(0, 0, 0.01);
                
                //draw text                                          
                _text = 'Toggle on and off different elements of the data:';
                _textWidth = 0.3;
                _textHeight = 0.01;
                _fontSize = 0.03;
                _textfontColor = new THREE.Color(0x262626);
                _textbgColor = new THREE.Color(0xFFFFFF);
                _textPos = new THREE.Vector3(0, 0.1, 0.01);

                this.DrawText(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, 1, _textPos,  this.ToggleUIGroup,'center');

                //draw toggle button
                _text="CO2 Emissions";
                _textWidth = 0.3;
                _textHeight = 0.07;
                _textfontColor = new THREE.Color(0xFFFFFF);
                _textbgColor = new THREE.Color(0x000000);
                _textPos = new THREE.Vector3(0, -0.1, .02);
                _fontSize=0.03;
                borderRadius=0;
                this.DrawButton(_text,_textWidth,_textHeight,_fontSize,_textPos,_textfontColor,_textbgColor,borderRadius,"swapModels",null,this.ToggleUIGroup);
                             
            } else{
                this.ToggleUIGroup.visible = true;
            }
            
        });
        //draw attribution
        //textbox
        //draw title
        if(this.AirQuality_CopyrightIndex==0) {
            _text="\u00A9 "+this.copyright;
            _textWidth = 0.2;
            _textHeight = 0.035;
            _fontSize = 0.02;
            _textfontColor = new THREE.Color(0xFFFFFF);
            _textbgColor = new THREE.Color(0xFFFFFF);
            _textbgOpacity =0;
            _textPos = new THREE.Vector3(0.5, -.8, 1.67);
            _textPadding = 0;
            _textBoxRadius = 0;

            this.AirQuality_CopyrightIndex = this.DrawTextBox(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, _textbgOpacity, _textPos, this.uiContainer, _textPadding, _textBoxRadius);
        } else{
            this.uiContainer.children[this.AirQuality_CopyrightIndex].visible=true;
        }
         
        //draw google logo
        var _imagePos = new THREE.Vector3(-0.5, -.8, 1.67);
        if(!this.AIrqualityIndicatorImage){
            this.AirQuality_AttributionLogo= this.DrawTexture(Airquality_AttributionLogo,.15,0.0453,_imagePos,this.uiContainer).then(image => {
                this.AirQuality_AttributionLogo=image;
            });
        } else{
            this.AirQuality_AttributionLogo.visible=true;
        }

        //draw indicator image
        var _imagePos = new THREE.Vector3(0, -.28, .01);
        if(!this.AIrqualityIndicatorImage){
            this.AirQuality_IndicatorIndex= this.DrawTexture(Airquality_Data_Indicator_Image,1,.132,_imagePos,this.uiContainer).then(image => {
                this.AIrqualityIndicatorImage=image;
            });
        } else{
            this.AIrqualityIndicatorImage.visible=true;
        }
    }

    DisplayDisclaimerUI(){
        //draw logo
        var _imagePos = new THREE.Vector3(0, 0.25, .01);
        this.DrawTexture(ColoredLogo,0.22,.173,_imagePos,this.uiContainer);
        //draw disclaimer
        var _text='Welcome to Geospatial Insights. \n \nBy proceeding, you confirm you have signed the pre-demo form to consent to the application Terms of Use and Privacy Policy and you agree to follow the appropriate headset usage guidelines.';
        var _textWidth = 1.2;
        var _textHeight = 0.07;
        var _fontSize = 0.035;
        var _textfontColor = new THREE.Color(0x000000);
        var _textbgColor = new THREE.Color(0xFFFFFF);
        var _textPos = new THREE.Vector3(0, -.05, .01);

        this.DrawText(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, 1, _textPos, this.uiContainer,'center');
        //draw button 
        _text="Start";
        _textWidth = 0.15;
        _textHeight = 0.07;
        _textfontColor = new THREE.Color(0xFFFFFF);
        _textbgColor = new THREE.Color(0x000000);
        _textPos = new THREE.Vector3(0, -0.3, .01);
        var borderRadius=0;
        this.StartButton=this.DrawButton("Start",_textWidth,_textHeight,null,_textPos,_textfontColor,_textbgColor,borderRadius,"StartExperience",null,this.uiContainer);
    }

    SavedDataStories(CityIndex){
        //if keyboard not opened, open it
        if(!this.keyboard){
            makeKeyboard(this);//open keyboard
        }
        this.keyboard.visible=true;

        //reset mainmenu
        this.CurrentScene = 1;//1 is the map scene
        this.uiContainer.children[this.MainMenu_InstructionIndex].visible = false; //hide main menu instruction
        this.keyboardInputText.visible = false;//hide input text
        this.uiContainer.children[this.MainMenu_TextInputFieldIndex].visible = false;//hide input text field
        this.uiContainer.children[this.MainMenu_SavedDataTextIndex].visible = false;//hide input text field
        //disable saved data buttons
        this.Storybuttons.forEach((obj) => {
            obj.visible=false;                                                             
        });

        //Set map coordinates
        //move map center to the new coordinates
        this.SelectedAddressName=this.StoryCoords[CityIndex][2];
        this.convertAddressToCoordinates(this.SelectedAddressName)
            .then(coordinates => {
                {
                    this.UpdateMapCenter(coordinates).then(() => {
                    }).catch(error => {
                        console.error(error);
                    });
                    //save the current center values
                    this.originalCenterLng = coordinates.longitude;
                    this.originalCenterLat = coordinates.latitude;
                }
            })
            .catch(error => console.error(error));
   
        //enable back button
        this.uiContainer.children[this.Map_BackButtonIndex].visible = true;
        //enable map
        if (this.htmlMesh != null) {
            this.htmlMesh.visible = true;
        }
        //enable square marker
        this.uiContainer.children[this.Map_SquareMarkerMeshIndex].visible = true;
        this.uiContainer.children[this.Map_SquareMarkerBorderIndex].visible = true;
        this.uiContainer.children[this.Map_SelectAreaIndex].visible = true;
    }

    StartExperience(){
        //clear disclaimer ui 
        for(let i=1;i<this.uiContainer.children.length;i++){
            this.deleteButton(this.uiContainer.children[i],this.uiContainer);
            
        }
        for(let i=1;i<this.uiContainer.children.length;i++){
            this.clearScene(this.uiContainer.children[i]);
            this.uiContainer.remove(this.uiContainer.children[i]);            
        }
        this.objsToTest=[];
        DrawMainMenu(this);
    }

    deleteButton(button, targetContainer) {
        // Remove the button from the container
        if (targetContainer && button) {
            targetContainer.remove(button);
        }
    
        // Dispose of the button's geometry, material, and any textures it might have
        if (button.children) {
            button.children.forEach(child => {
                if (child.geometry) child.geometry.dispose();
    
                if (child.material) {
                    if (Array.isArray(child.material)) {
                        child.material.forEach(material => material.dispose());
                    } else {
                        child.material.dispose();
                    }
                }
    
                if (child.texture) child.texture.dispose();
            });
        }
    
        console.log("Button has been deleted and resources disposed.");
    }

    DrawSquareMarker() {
        // Square with transparent material
        const Mygeometry = new THREE.PlaneGeometry(1, 1);
        const Mymaterial = new THREE.MeshBasicMaterial({
            color: 0xFFFFFF,
            transparent: true,
            opacity: 0.05
        });
        const squareMesh = new THREE.Mesh(Mygeometry, Mymaterial);
        squareMesh.position.set(0, -0.07, .007);
        squareMesh.scale.set(.5, 0.5, 0.1);

        // Square borders
        const edges = new THREE.EdgesGeometry(Mygeometry);
        const lineMaterial = new THREE.LineBasicMaterial({
            color: 0xFFFFFF,
            transparent: true,
            opacity: 1
        });
        const squareLine = new THREE.LineSegments(edges, lineMaterial);
        squareLine.position.set(0, -0.07, .007);
        squareLine.scale.set(.5, 0.5, 0.1);
        this.uiContainer.add(squareMesh, squareLine);
        this.Map_SquareMarkerMeshIndex = this.uiContainer.children.length - 2;
        this.Map_SquareMarkerBorderIndex = this.uiContainer.children.length - 1;

        //select area prompt
        var _text = 'Select area';
        var _textWidth = 0.25;
        var _textHeight = 0.05;
        var _fontSize = 0.035;
        var _textfontColor = new THREE.Color(0xFFFFFF);
        var _textbgColor = new THREE.Color(0xFF0000);
        var _textPos = new THREE.Vector3(0, -0.07, .01);
        var _textPadding = 0;
        var _textBoxRadius = 0.015;

        this.Map_SelectAreaIndex = this.DrawTextBox(_text, _textWidth, _textHeight, _fontSize, _textfontColor, _textbgColor, 0.35, _textPos, this.uiContainer, _textPadding, _textBoxRadius);

        this.uiContainer.children[this.Map_SquareMarkerMeshIndex].visible = false;
        this.uiContainer.children[this.Map_SquareMarkerBorderIndex].visible = false;
        this.uiContainer.children[this.Map_SelectAreaIndex].visible = false;
    }

    GoBackToMenu() {
        if (this.CurrentScene == 2) {
            //delete model
            if(this.cityModel.scene){
                this.clearScene(this.cityModel.scene);
                this.clearScene(this.OriginalcityModel);
            }
            
            //delete UI
            var child=this.uiContainer.children[this.AirQuality_AreaNameIndex];
            
            var child_a=this.uiContainer.children[this.AirQuality_DescriptionTextIndex];     
            
            var child_c=this.uiContainer.children[this.AirQuality_TitleIndex];

            this.uiContainer.remove(child_c);
            this.AIrqualityIndicatorImage.visible=false;
            this.uiContainer.remove(child_a);
            this.uiContainer.remove(child);
            this.jsonColors=[];//clear the colors

            this.ToggleUIGroup.visible = false;//hide toggle ui
            this.AirQuality_AttributionLogo.visible=false;//hide attribution logo
            this.uiContainer.children[this.AirQuality_CopyrightIndex].visible=false;
            
        }
        //disable map
        this.htmlMesh.visible = false;

        //disable square
        this.uiContainer.children[this.Map_SquareMarkerMeshIndex].visible = false;
        this.uiContainer.children[this.Map_SquareMarkerBorderIndex].visible = false;
        this.uiContainer.children[this.Map_SelectAreaIndex].visible = false;

        //disable back button
        this.uiContainer.children[this.Map_BackButtonIndex].visible = false;

        //hide keyboard
        this.keyboard.visible = false;//enable keyboard  

        //enable Main menu compnonents
        this.uiContainer.children[this.MainMenu_InstructionIndex].visible = true; //show main menu instruction
        this.uiContainer.children[this.MainMenu_TextInputFieldIndex].visible = true;//show input text field
        this.uiContainer.children[this.MainMenu_SavedDataTextIndex].visible=true;//enable saved Data
        //enable saved data buttons
        this.Storybuttons.forEach((obj) => {
            obj.visible=true;                                                             
        });
        this.keyboardInputText.set({ content: this.placeholderText });//reset input text
        this.keyboardInputText.visible = true;//show input text

        //reset mainmenu
        this.CurrentScene = 0;
    }

    updateButtons() {
        // Find closest intersecting object
        let intersect;

        if (this.renderer.xr.isPresenting) {
            // TODO implement controllers from three mesh
            this.vrControl.setFromController( 0, this.raycaster.ray );

            intersect = this.raycast();

            if (intersect){
                 console.log(intersect.point);
                }

            // Position the little white dot at the end of the controller pointing ray
            // TODO implement controllers from three mesh
            if (intersect && intersect.length > 0) {
                console.log(intersect.point);
                this.vrControl.setPointerAt(0, intersect.point);
            }
        } else if (this.mouse.x !== null && this.mouse.y !== null) {
            this.raycaster.setFromCamera(this.mouse, this.camera);

            intersect = this.raycast();
        }

        // Update targeted button state (if any)
        if (intersect && intersect.object.isUI) {
            
            if ((this.selectState && intersect.object.currentState === 'hovered') || this.touchState) {
                // Component.setState internally call component.set with the options you defined in component.setupState
                if (intersect.object.states['selected']) intersect.object.setState('selected');

            } else if (!this.selectState && !this.touchState) {

                // Component.setState internally call component.set with the options you defined in component.setupState
                if (intersect.object.states['hovered']){
                    intersect.object.setState('hovered');
                } 

            }
        }
        //detect map input
        if (this.CurrentScene == 1 && this.map && this.map.getLayer('v') && this.htmlMesh) {
            const intersects = this.raycaster.intersectObject(this.htmlMesh, true);

            if (intersects.length > 0 && this.selectState) {
                console.log("mouse coords: ", intersects[0].point.x, intersects[0].point.y);
                //convert mouse/tirgger scene pos to map's long and lang coordinates				
                const geoLocation = this.sceneToGeo(intersects[0].point.x, intersects[0].point.y);
                //const geoLocation =this.convertSceneToGeo(intersects[0].point.x, intersects[0].point.y);

                //move map center to the new coordinates
                var coord = new maptalks.Coordinate([geoLocation.lng, geoLocation.lat]);
                this.map.panTo(coord);
                console.log("panned to long and lang", coord);

                //update geo-scene reference 
                this.InitializeGeoCorners(coord.x, coord.y);
                console.log("map's center", this.map.getCenter());
                //save the current center values
                this.originalCenterLng = coord.x;
                this.originalCenterLat = coord.y;

                // Reset selectState to avoid multiple triggers on the same click
                this.selectState = false;
            }
        }

        // Update non-targeted buttons state
        this.objsToTest.forEach((obj) => {

            if ((!intersect || obj !== intersect.object) && obj.isUI) {
                // Component.setState internally call component.set with the options you defined in component.setupState
                if (obj.states['idle']) obj.setState('idle');
            }
        });
    }

    raycast() {
        return this.objsToTest.reduce((closestIntersection, obj) => {
            if(obj.visible==false){
                return closestIntersection;
            }

            const intersection = this.raycaster.intersectObject(obj, true);
            // if intersection is an empty array, we skip
            if (!intersection[0]) {
                return closestIntersection;
            }

            // if this intersection is closer than any previous intersection, we keep it
            if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
                // Make sure to return the UI object, and not one of its children (text, frame...)
                intersection[0].object = obj;
                
                return intersection[0];
            }
            return closestIntersection;
        }, null);
    }

    render = () => {
        this.updateButtons();

        ThreeMeshUI.update();

        this.controls.update();

        this.renderer.render(this.scene, this.camera);
    }
}

export default WebXRScene;