import * as THREE from 'three';
import VRControl from 'three-mesh-ui/examples/utils/VRControl.js';

export function createControls(sceneInstance) {
    //Mouse setup
    sceneInstance.mouse = new THREE.Vector2();
    sceneInstance.mouse.x = sceneInstance.mouse.y = null;

    sceneInstance.contr1 = new THREE.Vector2();
    sceneInstance.contr1.x = sceneInstance.contr1.y = null;

    sceneInstance.selectState = false;
    sceneInstance.touchState = false;

    //Mouse controls
    window.addEventListener('pointermove', (event) => {
        sceneInstance.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        sceneInstance.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('pointerdown', () => {
        sceneInstance.selectState = true;
        console.log("Mouse Down");
    });

    window.addEventListener('pointerup', () => {
        sceneInstance.selectState = false;
        console.log("Mouse Up");
    });

    //Touch controls
    window.addEventListener('touchstart', (event) => {
        sceneInstance.touchState = true;
        sceneInstance.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
        sceneInstance.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
        console.log("Mouse detected at" + sceneInstance.mouse);
    });

    window.addEventListener('touchend', () => {
        sceneInstance.touchState = false;
        sceneInstance.mouse.x = null;
        sceneInstance.mouse.y = null;
    });

    //VR controllers
    try {
        sceneInstance.vrControl = VRControl(sceneInstance.renderer, sceneInstance.camera, sceneInstance.scene);
        sceneInstance.scene.add( sceneInstance.vrControl.controllerGrips[ 0 ], sceneInstance.vrControl.controllers[ 0 ] );
        sceneInstance.vrControl.controllers[ 0 ].addEventListener( 'selectstart', () => {
            sceneInstance.selectState = true;
        } );
        sceneInstance.vrControl.controllers[ 0 ].addEventListener( 'selectend', () => {
            sceneInstance.selectState = false;
        } );
        sceneInstance.scene.add( sceneInstance.vrControl.controllerGrips[ 1 ], sceneInstance.vrControl.controllers[ 1 ] );
        sceneInstance.vrControl.controllers[ 1 ].addEventListener( 'selectstart', () => {
            sceneInstance.selectState = true;
        } );
        sceneInstance.vrControl.controllers[ 1 ].addEventListener( 'selectend', () => {
            sceneInstance.selectState = false;
        } );
    } catch (error) {
        console.log("VR Controllers not available" + error);
    }
}